import React from 'react'
import { Link, useNavigate } from 'react-router-dom';
import logo from "../../Util/logo_colour.png";
import "./Header.css"
const Header = () => {
  const history = useNavigate();
  return (
    <>
      <div className='header-top-information'>
        <div className='content-sizing header-top-content'>
          <span>Welcome to Canada One Robotics</span>
          <ul>
            <li>
              <i class="bi bi-telephone-fill"></i>
              <span>+1 204-957-8888</span>
            </li>
            <li>
              <i class="bi bi-envelope-fill"></i>
              <Link className='pure-link' to="/contact-us"><span>Contact Us</span></Link>
            </li>
            <li></li>
          </ul>
        </div>
      </div>
      <header>
        <div className='content-sizing header-content'>
          <img src={logo} alt="Company Logo" width="250"/>
          <nav>
            <ul className='header-menu'>
              <li><Link to="/"><span></span>Home</Link></li>
              <li><Link to="/about-us"><span></span>About Us</Link></li>
              <li className='drop-down-menu-container'><Link to=""><span></span>Our Product <i class="bi bi-chevron-down"></i></Link>
                <div className='drop-down-menu-products'>
                  <ul>
                    <li onClick={() => history('/product/mini')}>
                      <img width="65" src='https://www.orionstar.com/res/orics/down/ow001_20220714_bb9745c4e09fada85dba391cd741b3a5.png'/>
                      <span className='mt-2'>Reception Robot</span>
                      <h3>AI Robot Mini</h3>
                    </li>
                    <li onClick={() => history('/product/lucki')}>
                      <img width="90" src='https://www.orionstar.com/res/orics/down/ow001_20220715_3ba8af07b31e93cbf446cbf3e2d754d8.png'/>
                      <span>AI Delivery Robot</span>
                      <h3>Lucki</h3>
                    </li>
                  </ul>
                </div>
              </li>
              <li><Link to="/contact-us"><span></span>Contact Us</Link></li>
            </ul>
            <Link to="/contact-us" className='general-btn'>Schedule a Demo</Link>
          </nav>
        </div>
      </header>
    </>
  )
}

export default Header;

