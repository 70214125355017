import React, { useEffect } from 'react'
import { Link } from 'react-router-dom';
import PageHeader from '../../../Components/PageHeader/PageHeader';
import { motion } from "framer-motion";
import "./About.css";

export const About = () => {
  useEffect(() => {
    window.scroll(0,0)
  }, [])
  return (
    <motion.div initial={{opacity: 0}} animate={{opacity: 1}} exit={{opacity: 0}}>
      <PageHeader name="About Us"/>
      <div className='about-section-holder'>
        <div className='content-sizing about-section-holder-content'>
          <div className='images-container-left-side'>
            <img src="./Images/pics/3.JPG" width="400" className="image-container" loading='lazy'/>
            <img src="./Images/pics/2.JPG" width="350" className="image-container second-image" loading='lazy'/>
          </div>
          <div className='content-for-about-right'>
            <h2>What is Canada One Robotics?</h2>
            <p>Canada One Robotics is a Canadian-owned robotics technology marketing company that serves as the general reseller for Orion Star Robotics in Canada. We offer robotics solutions and apply our cutting-edge autonomous and artificial intelligence technologies to enhance security, business operations, and daily operations.</p>
            <p>A new generation of service robots that blends "AI + software + hardware + services" is offered by ORIONSTAR, a breakthrough in the industry. The Smart Service Robot Family was developed by ORIONSTAR using its own Whole-Chain AI Technology and proprietary algorithms. such are intelligent retail robots, intelligent delivery robots, and intelligent receptionist robots.</p>
            <Link to="/about-us" className='general-btn'>Discover More</Link>
          </div>
        </div>
      </div>

      <div className='services-section-holder'>
        <div className='content-sizing services-section-holder-content'>
          <h2>Technical Advantage</h2>
          <p>OrionStar autonomously conducts full-chain AI technology research and development, builds a very extensive scenario application area.</p>
          <div className='item-service'>
            <div className='icon-hold'>
              <i class="bi bi-pc-display"></i>
            </div>
            <div className='content-about-single-item'>
              <h2>Voice Full Chain Technology</h2>
              <p>The voice interaction system independently developed by OrionStar has the industry-leading full-link far-field voice technology and a rich voice content matrix, and supports small, non-secret payments based on voiceprints. today, the fully self-developed Orion Voice OS technology has been applied to the products of many partners such as Xiaomi Xiaoai, Huawei AI speaker, Midea Xiaomei AI speaker, Himalaya Xiaoya speaker, Cheetah Mobile Xiaobao AI speaker, etc., supporting China's smart speaker market share exceeds 30%, becoming a widely used AI voice system. According to statistics, the Orion Voice OS technology has more than 30 million voice command requests per day, with millions of hours of far-field voice data accumulation, and cumulative activation of more than 100 million devices.</p>
            </div>
          </div>
          <div className='item-service'>
            <div className='icon-hold'>
              <i class="bi bi-pc-display"></i>
            </div>
            <div className='content-about-single-item'>
              <h2>Microphone Array</h2>
              <p>Self-developed robot-specific ring 6-microphone array, 360-degree full-field coverage, 150 million wake-up practice tests, 5 meters effective far-field interaction, customized for the scenes.</p>
            </div>
          </div>
          <div className='item-service'>
            <div className='icon-hold'>
              <i class="bi bi-pc-display"></i>
            </div>
            <div className='content-about-single-item'>
              <h2>Chip + Algorithm</h2>
              <p>It is equipped with an industry-leading robotic nervous system, and based on hardware-accelerated deep learning algorithms, it achieves extremely fast facial and human recognition, voice semantic customization capabilities, far-field recognition, wake-free, multi-round interaction, voiceprint technology and other industry-leading technologies. In addition, OrionStar and Rockchip Microelectronics jointly released the industry's first full-chain AI voice chip-OS1000RK. OS1000RK uses a low-power and high-performance CPU core-64-bit 4-core ARMCortex-A35, integrated CODEC (8-channel ADC + 2-channel DAC), can support 8 microphone arrays at low cost, its hardware voice detection module (VAD ) It can achieve a very low standby function, and a dedicated instruction set can make the operation of the neural network more optimized.</p>
            </div>
          </div>
          <div className='item-service'>
            <div className='icon-hold'>
              <i class="bi bi-pc-display"></i>
            </div>
            <div className='content-about-single-item'>
              <h2>Full perception Visual Recognition</h2>
              <p>The self-developed face recognition algorithm has won the Champion Microsoft Million Celebrity Recognition Contest. It can accurately perform face recognition and follow-up, gender and age analysis, gesture recognition, object recognition and positioning, and support access control, payment, meeting reservations, and inspect application scenes and mobile applications deployment. At the same time, vision technology has been applied to service robot products to realize functions such as active visual interaction and assisted navigation and obstacle avoidance.</p>
            </div>
          </div>
          <div className='item-service'>
            <div className='icon-hold'>
              <i class="bi bi-pc-display"></i>
            </div>
            <div className='content-about-single-item'>
              <h2>Indoor Autonomous Navigation System</h2>
              <p>The self-developed indoor navigation system has industry-leading low-cost multi-modal navigation technology, multi-sensor fusion, accurate positioning, active obstacle avoidance, autonomous route optimization, all-day battery life, and automatic recharge when the battery is low.</p>
            </div>
          </div>
          <div className='item-service'>
            <div className='icon-hold'>
              <i class="bi bi-pc-display"></i>
            </div>
            <div className='content-about-single-item'>
              <h2>Six-Axis Consumer-Grade Robotic Arm</h2>
              <p>Six-axis robotic arm: integrates industry-leading robotic arm vision and motion control algorithms, provides an intelligent teaching system, remote background monitoring system and rich artificial intelligence API. Around it, you will have the ability to build a powerful robot, and OrionStar will provide one-stop service to help launch the product.</p>
            </div>
          </div>
        </div>
      </div>
    </motion.div>
  )
}

export default About;