import React, { useEffect, useState } from 'react'
import {Routes, Route, Navigate, useNavigate} from "react-router-dom";
import Loading from './Components/Loading/Loading';
import {firebase, auth, db} from './Util/Firebase'
import Main from './Pages/Main';
import About from './Pages/Main/About/About';
import Contact from './Pages/Main/Contact/Contact';
import LandingPage from './Pages/Main/LandingPage/LandingPage';
import Dashboard from './Dashboard/Dashboard';
import RobotPage from './Dashboard/RobotPage/RobotPage';
import RobotPageAdd from './Dashboard/RobotPage/RobotPageAdd';
import RobotPageEdit from './Dashboard/RobotPage/RobotPageEdit';
import Inquires from './Dashboard/ProductInquries/Inquires'
import InquireView from './Dashboard/ProductInquries/InquireView'
import Login from './Dashboard/Login'
import Settings from './Dashboard/settings/Settings'
import ChangePass from './Dashboard/settings/ChangePass'
import PageOrderChange from './Dashboard/settings/PageOrderChange'
import Lucki from './Pages/Main/Product/Lucki';
import Mini from './Pages/Main/Product/Mini';
const App = () => {
  const history = useNavigate();
  const [loading, setLoading] = useState(true);
  const [pages, setPages] = useState();
  const [extraInfo, setExtraInfo] = useState();
  const [inquires, setInquires] = useState();
  const [currentUser, setCurrentUser] = useState();
  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
        setCurrentUser(user);
        setLoading(false);
    });

    return unsubscribe;
  }, []);
  useEffect(() => {
    db.collection("Pages").onSnapshot((querySnapshot) => {
      let tempList = [];
      querySnapshot.forEach((doc) => {
        tempList.push({data: doc.data(),id : doc.id});
      });
      tempList.sort((a,b) => a.data.order - b.data.order);
      setPages(tempList);
    });
  }, [])
  useEffect(() => {
    db.collection("Pages").onSnapshot((querySnapshot) => {
      let tempList = [];
      querySnapshot.forEach((doc) => {
        tempList.push({data: doc.data(),id : doc.id});
      });
      tempList.sort((a,b) => a.data.order - b.data.order);
      setPages(tempList);
    });
  }, [])
  useEffect(() => {
    db.collection("Inquires").onSnapshot((querySnapshot) => {
      let tempList = [];
      querySnapshot.forEach((doc) => {
        tempList.push({data: doc.data(),id : doc.id});
      });
      setInquires(tempList);
    });
  }, [])
  const login = (email, password) => {
    console.log("login");
    return auth.signInWithEmailAndPassword(email, password);
  };

  const add = async (type,obj,id) => {
    try{
      await firebase.firestore().collection(type).doc(id).set(obj);
    }
    catch(e){
      return e.message
    } 
  }

  const edit = async (obj,type,id) => {
    try{
      await firebase.firestore().collection(type).doc(id).update(obj);
    }
    catch(e){
      return e.message
    } 
  }
  const del = async (type, id) => {
    try {
      await firebase.firestore().collection(type).doc(id).delete();
      if (type == "Pages"){

      }
    } catch(e){
      return e.message;
    } 
  }


  const logout = () => {
    auth.signOut().then((res) => {
        setCurrentUser(null);
        history("/login");
        return res;
      }).catch((err) => {
        return err;
      });
  };


  useEffect(() => {
    setInterval(() => {
      setLoading(false);
    }, 2000);
  }, [])

  
  return (
    <>
    {!loading ?
      <Routes>
        <Route path='/' element={<Main/>}>
          <Route index element={<LandingPage/>}/>
          <Route path='/about-us' element={<About/>}/>
          <Route path='/product/lucki' element={<Lucki/>}/>
          <Route path='/product/mini' element={<Mini/>}/>
          <Route path='/contact-us' element={<Contact/>}/>
          
        </Route>
        <Route path="/login" element={currentUser ? <Navigate to="/dashboard"/> : <Login login={login}/>}/>
          <Route path="dashboard" element={currentUser ? <Dashboard del={del} add={add} edit={edit} currentUser={currentUser} logout={logout}/> : <Navigate to="/login"/>}>
            <Route path='/dashboard/robotPage' element={<RobotPage del={del} add={add} edit={edit} pages={pages} inquires={inquires}/>}/>
            <Route path='/dashboard/robotPage/add' element={<RobotPageAdd del={del} add={add} edit={edit} pages={pages} inquires={inquires}/>}/>
            <Route path='/dashboard/robotPage/edit/:id' element={<RobotPageEdit del={del} add={add} edit={edit} pages={pages} inquires={inquires}/>}/>
            <Route path='/dashboard/inquires' element={<Inquires del={del} add={add} edit={edit} pages={pages} inquires={inquires}/>}/>
            <Route path='/dashboard/inquires/:id' element={<InquireView del={del} add={add} edit={edit} pages={pages} inquires={inquires}/>}/>
            <Route path='/dashboard/settings' element={<Settings del={del} add={add} edit={edit} pages={pages} inquires={inquires}/>}/>
            <Route path='/dashboard/settings/changePass' element={<ChangePass del={del} add={add} edit={edit} pages={pages} inquires={inquires}/>}/>
            <Route path='/dashboard/settings/pageOrderChange' element={<PageOrderChange del={del} add={add} edit={edit} pages={pages} inquires={inquires}/>}/>
        </Route>
      </Routes> : <Loading/>
      }
      
    </>
  )
}

export default App;