import React, {useEffect} from 'react';
import { Link } from 'react-router-dom';
import PageHeader from '../../../Components/PageHeader/PageHeader';
import { motion } from "framer-motion";
import "./Product.css";
const Lucki = () => {
  useEffect(() => {
    window.scroll(0,0)
  }, [])
  return (
    <motion.div initial={{opacity: 0}} animate={{opacity: 1}} exit={{opacity: 0}}>
      <PageHeader name="Lucki"/>
      <div className='about-section-holder bottom-border-red'>
        <div className='content-sizing product-face-description'>
          <img width="200" src='https://www.orionstar.com/res/orics/down/ow001_20220715_3ba8af07b31e93cbf446cbf3e2d754d8.png'/>
          <div className='content-for-about-right'>
            <p><strong>Lucki，</strong> is an efficient delivery robot and improves customer psychological safety, perceived value, and hospitality service quality. Customers will appreciate Lucki’s hygienic delivery capabilities.</p>
            <ul className='stats-robot-item'>
              <li>Up to<span>3x</span> More Efficient Delivery</li>
              <li>Up to<span>97%</span>Customer Satisfaction</li>
              <li>Up to<span>80%</span>Airtightness</li>
            </ul>
          </div>
        </div>
      </div>

      <div className='video-info-container bottom-border-red'>
        <div className='content-sizing product-flex'>
          <div className='item-video-container'>
            <video width="100%"  controls><source src="https://www.orionstar.com/res/orics/down/ow001_20220728_43a31bb099b095a3147e17654b970ae4.mp4" type="video/mp4"/>Your browser does not support video playback</video>          
          </div>
          <div className='half-width-video-info rm-padding-right'>
            <h2>See Clearly, Walk Steadily</h2>
            <p className='size-bigger-font'>Excellent 3D depth detection reduces the risk that Lucki will enter a restricted area by mistake. Lucki can rapidly scan the surrounding environment and return the sensing signal with zero delay, allowing it to safely avoid obstacles, travel steadily, and navigate accurately.</p>
          </div>
        </div>
      </div>

      <div className='video-info-container bottom-border-red'>
        <div className='content-sizing product-flex'>
          <div className='half-width-video-info rm-padding-right'>
            <h2>Advanced Dual SLAM Solution for Full Coverage in Any Scenario</h2>
            <p className='size-bigger-font'>Dual SLAM solution allows Lucki to recognize locations markers so it does not get lost at night. It automatically corrects its walking route to avoid deviation from navigation.</p>
          </div>
          <div className='item-video-container'>
            <video width="100%" controls><source src="https://www.orionstar.com/res/orics/down/ow001_20220728_f4ac8dd366816e2e7a0ed430b7e8fbb6.mp4" type="video/mp4"/>Your browser does not support video playback</video>
          </div>
        </div>
      </div>

      <div className='video-info-container bottom-border-red'>
        <div className='content-sizing product-flex'>
          <div className='item-video-container'>
            <video controls width="100%" ><source src="https://www.orionstar.com/res/orics/down/ow001_20220728_6b4b88b11735598bfcfcda51577bb8a1.mp4" type="video/mp4"/>Your browser does not support video playback</video>
          </div>
          <div className='half-width-video-info rm-padding-right'>
            <h2>Multi-robot Cooperation</h2>
            <p className='size-bigger-font'>When multiple Lucki working in the same restaurant meet at a fork in the road, they can autonomously avoid each other without human intervention by following the priority of the robot number and traveling intelligently.</p>
          </div>
        </div>
      </div>

      <div className='video-info-container sepc-bg-img'>
        <div className='content-sizing spects'>
          <h2>Specifications</h2>
          <ul>
            <li>
              <span>General Parameters</span>
              <span>Overall Size	</span>
              <span>526mm×493mm×1320mm</span>
            </li>
            <li>
              <span></span>
              <span>Net Weight</span>
              <span>40Kg</span>
            </li>
            <li>
              <span></span>
              <span>Color</span>
              <span>Pearl white + Elegant black</span>
            </li>
            <li>
              <span></span>
              <span>Body Material	</span>
              <span>Aviation aluminum; High Strength PC+ABS (Food Grade Plastic Material)</span>
            </li>
            <li>
              <span></span>
              <span>Screen Size	</span>
              <span>10.1 inch; 1080P</span>
            </li>
          </ul>
          <ul className='border-top-with-padding'>
            <li>
              <span>Loading Capability</span>
              <span>Loading Space	</span>
              <span>3 tiers /4 tiers adjustable, the area of each tier is 0.18 ㎡</span>
            </li>
            <li>
              <span></span>
              <span>Single Tray Load</span>
              <span>10Kg</span>
            </li>
            <li>
              <span></span>
              <span>Total Load</span>
              <span>40Kg</span>
            </li>
            <li>
              <span></span>
              <span>Gradeability	</span>
              <span>5 Degrees</span>
            </li>
            <li>
              <span></span>
              <span>Interactive Ability	</span>
              <span>Voice Interaction + Touch Screen Control</span>
            </li>
          </ul>

          <ul className='border-top-with-padding'>
            <li>
              <span>System Performance</span>
              <span>Hardware Platform	</span>
              <span>Qualcomm 8-core chip + 32-bit Microchip MCU+Intel®  RealSense ™ depth sensor</span>
            </li>
            <li>
              <span></span>
              <span>Operating System	</span>
              <span>Deep customized Robot OS operating system based on Android 9.0</span>
            </li>
            <li>
              <span></span>
              <span>Navigation System	</span>
              <span>Lidar + Visual Positioning + Visual Obstacle Avoidance + Odometer + IMU</span>
            </li>
            <li>
              <span></span>
              <span>Microphone Array	</span>
              <span>6 microphone arrays, 360° sound source positioning, 5 m sound range</span>
            </li>
            <li>
              <span></span>
              <span>Cruising Speed	</span>
              <span>0.5 ~ 1.2m/s (adjustable)</span>
            </li>
            <li>
              <span></span>
              <span>Battery Life	</span>
              <span>12 ~ 15 hours (according to actual working conditions)</span>
            </li>
            <li>
              <span></span>
              <span>Positioning Accuracy	</span>
              <span>Centimeter Level</span>
            </li>
          </ul>

          <ul className='border-top-with-padding'>
            <li>
              <span>Network and Charging</span>
              <span>Network Supports	</span>
              <span>4G  supports TDD-LTE, FDD-LTE WiFi supports 2.4G/5G</span>
            </li>
            <li>
              <span></span>
              <span>Charging Mode	</span>
              <span>Charging via Cable</span>
            </li>
            <li>
              <span></span>
              <span>Charging  Time	</span>
              <span>3.5 h</span>
            </li>
          </ul>
        </div>
      </div>

    </motion.div>
  )
}

export default Lucki