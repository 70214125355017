import React, {useState, useEffect} from 'react'
import { Link, useOutletContext, useNavigate } from 'react-router-dom'
import { firebase } from '../../Util/Firebase';
 const RobotPage = (props) => {
  const history = useNavigate();
  const [setDeleteBox, setDeleteId] = useOutletContext();
  const [loading, setLoading] = useState(true);

  return (
   <>
   <div className='header-content-right-page'>
      <div className='content-sizing-db wrapper-db-content'>
        <div className='header-and-create-button'>
          <h3>Pages</h3>
          <Link to="/dashboard/robotPage/add" className="btn-general primary-btn">Create <i class="bi bi-plus-lg"></i> </Link>
        </div>
        <section className="card card-light card-body border-0 shadow-sm p-4 mt-5 sec-response" id="basic-info">
          <table className='activity-table'>
            <thead>
              <th>Title</th>
              <th>Path</th>
              <th>Created Date</th>
              <th>Actions</th>
            </thead>
            <tbody>
            {(props.pages &&  props.pages.map((item, index) => (
                <tr key={index}>
                  <td><Link to={item.data.Path}>{item.data.Title}</Link></td>
                  <td>{item.data.Path}</td>
                  <td>{item.data.Date}</td>
                  <td><a href="#" className="btn-danger delete-button-table" onClick={() => {setDeleteBox(true); setDeleteId({...item, type: "Pages"})}}><i class="bi bi-trash3"></i> Delete</a><Link className="edit-button" to={`/dashboard/robotPage/edit/${item.id}`}><i class="bi bi-binoculars"></i> Edit</Link></td>
                </tr>
              )))}
            </tbody>
            { props.pages != undefined && props.pages.length <= 0 && 
            <div className='centering-messages mt-5'>
              <h4>No Items Found</h4>
              <Link className="btn-general primary-btn mt-3" to="/dashboard/robotPage/add">Add Product</Link>
            </div>
            }
          </table>
        </section>
      </div>
    </div>
   </>
  )
}

export default RobotPage