import { useEffect, useState , useRef} from "react";
import {Link} from 'react-router-dom';
import { useNavigate, useParams } from "react-router-dom";
import * as React from 'react';
const RobotPageAdd = (props) => {
  const history = useNavigate();
  const [error, setError] = useState("");
  const [userInput, setUserInput] = useState({
    Date: new Date().toLocaleDateString("en-US"),
    Path:"",
    Title:"",
  });

  const updateUserInput = (e) => {
    setUserInput(prevInput => ({
      ...prevInput, [e.target.name]: e.target.value
    }));
  }

  const onSubmit = async (e) => {
    e.preventDefault();
    let item = userInput
    var bool = props.pages.find((itm) => itm.id == item.Title);
    item.html = document.getElementById("editArr").innerHTML;
    if(bool == undefined){
      try{
        item.Path = `/${item.Title.toLowerCase()}`
        let userDetails = await props.add("Pages",item, item.Title);
        console.log( await userDetails);
        history('/dashboard/robotPage');
      }
      catch(e){
        console.log(e);
        setError(e.message);
      }
  } else {
    setError(true);
  }

  }
  return (
  <>
        <div className='header-content-right-page'>
      <div className='content-sizing-db wrapper-db-content'>
        <div className='header-and-create-button'>
          <h3>Create New Page</h3>
          <Link to="/dashboard/robotPage" className="btn-general primary-btn"><i className="bi bi-arrow-left"></i> Back</Link>
        </div>
        <form onSubmit={onSubmit} className="card card-light card-body border-0 shadow-sm p-4 mt-5" id="basic-info">
  <div className="row form-row">
  <h2 className="h4 mb-4">Page Details</h2>
  
  <button type="submit" className="btn-general primary-btn blue mb-4" > Submit</button>
  </div>
  <div className="row mt-3">


    <div className="col">
      
      <label className="form-label text-dark" htmlFor="c-name">Title</label>
      {error ? <p><i class="error">*Already Exists</i></p>: ""}
      <input
      
        className="form-control form-control-md form-control-dark"
        id="title"
        name="Title"
        type="text"
        onChange={updateUserInput}
      />
    </div>
  </div>
 
  
    
</form>
      
          <section className=" drop card card-light card-body border-0 shadow-sm p-4 mt-5" id="basic-info">
              <div className="row">
             <div className="col">
              <label className="form-label text-dark" htmlFor="c-name">Description<span>*</span></label>
          <div className="border-0  shadow-sm width-editor" id="editArr" contenteditable="true">
          <h1>world</h1>
          <p>world</p>
        </div>
            </div>
          </div>
          </section>
      </div>
      
    </div>
  </>
  )
}


export default RobotPageAdd;