import React, {useEffect} from 'react';
import { Link } from 'react-router-dom';
import PageHeader from '../../../Components/PageHeader/PageHeader';
import { motion } from "framer-motion";
import "./Product.css";
const Mini = () => {
  useEffect(() => {
    window.scroll(0,0)
  }, [])
  return (
    <motion.div initial={{opacity: 0}} animate={{opacity: 1}} exit={{opacity: 0}}>
      <PageHeader name="Mini"/>
      <div className='about-section-holder bottom-border-red'>
        <div className='content-sizing product-face-description'>
          <img width="150" src='https://www.orionstar.com/res/orics/down/ow001_20220714_bb9745c4e09fada85dba391cd741b3a5.png'/>
          <div className='content-for-about-right'>
            <p>Mini, is a master of greeting and leading. The powerful voice function and large interactive screen are perfect for a variety of reception needs, and the smaller body is more flexible to cover a greater range of scenarios. Mini provides customers an unparalleled service experience.</p>
            <ul className='stats-robot-item'>
              <li>Up to<span>27+</span> Languages Supported</li>
              <li>Up to<span>55cm</span>Passable Width</li>
              <li>Up to<span>97%</span>Customer Satisfaction</li>
            </ul>
          </div>
        </div>
      </div>

      <div className='video-info-container bottom-border-red'>
        <div className='content-sizing product-flex'>
          <div className='item-video-container'>
          <video poster2="https://www.orionstar.com/res/orics/down/ow001_20220715_61b1ca724ce8ed64c1d14946a40a2843.jpg" muted autoplay loop controls width="100%" height="100%"><source src="https://www.orionstar.com/res/orics/down/ow001_20220714_2265bc5e2c93ff17ee0852b8d45b1ded.mp4" type="video/mp4" />Your browser does not support video playback</video>
          </div>
          <div className='half-width-video-info rm-padding-right'>
            <h2>Powerful Interactive Capabilities</h2>
            <p className='size-bigger-font'>Specially developed voice and visual capabilities guarantee a first-class interactive experience. Mini detects the presence of people and takes the initiative to greet and interact with them.</p>
          </div>
        </div>
      </div>

      <div className='video-info-container bottom-border-red'>
        <div className='content-sizing product-flex'>
          <div className='half-width-video-info rm-padding-right'>
            <h2>Multi-Robot Cooperation</h2>
            <p className='size-bigger-font'>When the paths of more than one Mini intersect, intelligent priority numbering allows them to avoid collision without the need for human intervention..</p>
          </div>
          <div className='item-video-container'>
          <video poster2="https://www.orionstar.com/res/orics/down/ow001_20220715_0c58ac29240fc53df4803fd65810cd75.jpg" muted autoplay loop controls width="100%" height="100%"><source src="https://www.orionstar.com/res/orics/down/ow001_20220714_c8995ce867a7e7564399ba39ffd00a8c.mp4" type="video/mp4" />Your browser does not support video playback</video>
          </div>
        </div>
      </div>

      <div className='video-info-container bottom-border-red'>
        <div className='content-sizing product-flex'>
          <div className='item-video-container'>
          <video poster2="https://www.orionstar.com/res/orics/down/ow001_20220715_9c8ba602bf14b445108bc6b2da7bc931.jpg" muted autoplay loop controls width="100%" height="100%"><source src="https://www.orionstar.com/res/orics/down/ow001_20220714_17a4c91b1d84afbfe5d41842385d5ae1.mp4" type="video/mp4" />Your browser does not support video playback</video>
          </div>
          <div className='half-width-video-info rm-padding-right'>
            <h2>Reliable Passability</h2>
            <p className='size-bigger-font'>With a minimum channel chassis diameter of 55cm, Mini is guaranteed to operate flexibly in small spaces.</p>
          </div>
        </div>
      </div>

      <div className='video-info-container sepc-bg-img'>
        <div className='content-sizing spects'>
          <h2>Specifications</h2>
          <ul>
            <li>
              <span>General Parameters</span>
              <span>Size</span>
              <span>1000x410x410mm</span>
            </li>
            <li>
              <span></span>
              <span>Net Weight</span>
              <span>21kg</span>
            </li>
            <li>
              <span></span>
              <span>Product Color</span>
              <span>Starry Bright White, Starry Night Black</span>
            </li>
            <li>
              <span></span>
              <span>Storage Temperature</span>
              <span>-20℃~70℃</span>
            </li>
            <li>
              <span></span>
              <span>Operating Humidity</span>
              <span>(RH): 10%~90%</span>
            </li>
            <li>
              <span></span>
              <span>Operating Temperature</span>
              <span>0℃~40℃</span>
            </li>
            <li>
              <span></span>
              <span>Screen Size</span>
              <span>14 inch</span>
            </li>
            <li>
              <span></span>
              <span>Screen Resolution</span>
              <span>1920px*1080px</span>
            </li>
            <li>
              <span></span>
              <span>Microphone</span>
              <span>6-Mic Ring Array</span>
            </li>

            <li>
              <span></span>
              <span>Network</span>
              <span>Wi-Fi: 2.4G/5G, 4G</span>
            </li>
            <li>
              <span></span>
              <span>Operation System</span>
              <span>Based on Android 9</span>
            </li>
            <li>
              <span></span>
              <span>Battery</span>
              <span>Lithium Battery, Capacity: 10.4Ah，Voltage25.2V</span>
            </li>
            <li>
              <span></span>
              <span>Charging Pole Size</span>
              <span>204x221x251 mm</span>
            </li>
            <li>
              <span></span>
              <span>Package Dimensions</span>
              <span>1100x520x520 mm</span>
            </li>
            <li>
              <span></span>
              <span>IP Rating</span>
              <span>IP23</span>
            </li>
          </ul>
        </div>
      </div>
    </motion.div>
  )
}

export default Mini;