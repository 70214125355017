import React, { useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom';
import { motion } from "framer-motion";
import Title from 'react-vanilla-tilt'
import picone from "../../../Util/package.png";
import pictwo from "../../../Util/restaurant.png";
import picthree from "../../../Util/warehouse.png";
import "./LandingPage.css"
export const LandingPage = () => {

  const history = useNavigate();

  useEffect(() => {
    window.scroll(0,0)
  }, [])
  
  return (
    <motion.div initial={{opacity: 0}} animate={{opacity: 1}} exit={{opacity: 0}}>
      <div className='slider-content-holder'>
        <div className='content-sizing slider-info-content'>
          <div className='slider-content-left-side'>
            <h1>World’s Leading Machine Learning Company</h1>
            <p>The ORIONSTAR Smart Service Robot Family is now serving more than 20 scenarios, including restaurants, hotels, healthcare, exhibition halls, shopping malls, government affairs, and business campuses.</p>
            <div>
              <Link to="/contact-us" className='general-btn'>Get Started Free <i class="bi bi-arrow-right"></i></Link>
              <Link to="/about-us" className='text-btn'>Read More</Link>
            </div>
          </div>
          <div className='slider-content-right-side'>
            <Title className="fix-title-default" options={{ scale: 10, max: 100, axis: "X" }}>
            <img src="./Images/slider.png" width="300" loading='lazy'/> 
            </Title>
          </div>
        </div>
      </div>

      <div className='three-box-container'>
        <div className='content-sizing three-box-content'>
          <h2>The Utilization of Robots</h2>
          <p>Our robots can be employed in many environments, where they can learn to adapt and provide assistance.</p>
          <ul>
            <li>
              <div className='image-container-top'><img src={pictwo}/></div>
              <h2>Restaurants</h2>
              <p>Our industrial robots are being included in restaurants more and more to reduce time and space requirements, as well as to enhance cleanliness and safety.</p>
            </li>
            <li>
              <div className='image-container-top'><img src={picone}/></div>
              <h2>Delivery</h2>
              <p>Our Delivery robots offer an automated service, transporting customers' internet purchases of food or other goods. They are autonomous in their operation, consisting of a space for the packages they transport, placed on wheels, and outfitted with cameras and sensors.</p>
            </li>
            <li>
              <div className='image-container-top'><img src={picthree}/></div>
              <h2>Warehouses</h2>
              <p>Our robots promote improved inventory management, minimize overhead and operational costs, expedite order fulfillment, and mitigate/eliminate mistakes.</p>
            </li>
          </ul>
        </div>
      </div>

      <div className='about-section-holder'>
        <div className='content-sizing about-section-holder-content'>
          <div className='images-container-left-side'>
            <img src="./Images/pics/3.JPG" width="400" className="image-container" loading='lazy'/>
            <img src="./Images/pics/2.JPG" width="350" className="image-container second-image" loading='lazy'/>
          </div>
          <div className='content-for-about-right'>
            <h2>What is Canada One Robotics?</h2>
            <p>Canada One Robotics is a Canadian-owned robotics technology marketing company that serves as the general reseller for Orion Star Robotics in Canada. We offer robotics solutions and apply our cutting-edge autonomous and artificial intelligence technologies to enhance security, business operations, and daily operations.</p>
            <p>A new generation of service robots that blends "AI + software + hardware + services" is offered by ORIONSTAR, a breakthrough in the industry. The Smart Service Robot Family was developed by ORIONSTAR using its own Whole-Chain AI Technology and proprietary algorithms. such are intelligent retail robots, intelligent delivery robots, and intelligent receptionist robots.</p>
            <Link to="/about-us" className='general-btn'>Discover More</Link>
          </div>
        </div>
      </div>

      <div className='services-section-holder'>
        <div className='content-sizing services-section-holder-content'>
          <h2>Technical Advantage</h2>
          <p>OrionStar autonomously conducts full-chain AI technology research and development, builds a very extensive scenario application area.</p>
          <ul>
            <li>
              <div>
                <i class="bi bi-pc-display"></i>
              </div>
              <h3>Voice Full Chain Technology</h3>
              <p>The voice interaction system independently developed by OrionStar has the industry-leading full-link far-field voice technology and a rich voice content matrix, and supports small, non-secret payments based on voiceprints.</p>
            </li>
            <li>
              <div>
                <i class="bi bi-code-slash"></i>
              </div>
              <h3>Microphone Array</h3>
              <p>Self-developed robot-specific ring 6-microphone array, 360-degree full-field coverage, 150 million wake-up practice tests, 5 meters effective far-field interaction, customized for the scenes.</p>
            </li>
            <li>
              <div>
                <i class="bi bi-graph-up-arrow"></i>
              </div>
              <h3>Chip + Algorithm</h3>
              <p>It is equipped with an industry-leading robotic nervous system, and based on hardware-accelerated deep learning algorithms, it achieves extremely fast facial and human recognition, voice semantic customization capabilities, far-field recognition, wake-free, multi-round interaction, voiceprint technology and other industry-leading technologies.</p>
            </li>
            <li>
              <div>
                <i class="bi bi-hospital"></i>
              </div>
              <h3>Full perception Visual Recognition</h3>
              <p>The self-developed face recognition algorithm has won the Champion Microsoft Million Celebrity Recognition Contest.</p>
            </li>
            <li>
              <div>
                <i class="bi bi-incognito"></i>
              </div>
              <h3>Indoor Autonomous Navigation System</h3>
              <p>The self-developed indoor navigation system has industry-leading low-cost multi-modal navigation technology, multi-sensor fusion, accurate positioning, active obstacle avoidance, autonomous route optimization, all-day battery life, and automatic recharge when the battery is low.</p>
            </li>
            <li>
              <div>
                <i class="bi bi-car-front"></i>
              </div>
              <h3>Six-Axis Consumer-Grade Robotic Arm</h3>
              <p>Six-axis robotic arm: integrates industry-leading robotic arm vision and motion control algorithms, provides an intelligent teaching system, remote background monitoring system and rich artificial intelligence API.</p>
            </li>
            
          </ul>
        </div>
      </div>

      <div className='video-info-container'>
        <div className='content-sizing video-info-wrapper'>
          <div className='half-width-video-info'>
            <h2>Learn about ORIONSTAR</h2>
            <p>A new generation of service robots that blends "AI + software + hardware + services" is offered by ORIONSTAR, a breakthrough in the industry. The Smart Service Robot Family was developed by ORIONSTAR using its own Whole-Chain AI Technology and proprietary algorithms. such are intelligent retail robots, intelligent delivery robots, and intelligent receptionist robots.</p>
            <p>The ORIONSTAR Smart Service Robot Family today has 15 robots, and they are used in over 20 different applications, such as hotels, restaurants, medical facilities, exposition halls, malls, offices, and corporate campuses. According to industry data, it has deployed close to 20,000 intelligent service robots globally.</p>
            <a className='general-btn' href="https://en.orionstar.com/" target="_blank">Explore More</a>
          </div>
        </div>
      </div>

      <div className='Product-View-container'>
        <div className='content-sizing Product-View-wrapper'>
          <h2>Our Products</h2>
          <p>Our business's goal is to provide the Canadian market with useful and effective robots. The robots you may see here are examples that we now distribute in our area.</p>
          <ul>
            <li>
              <div>
                <h2>Lucki</h2>
                <span>AI Delivery Robot</span>
                <p>Lucki，is an efficient delivery robot and improves customer psychological safety, perceived value, and hospitality service quality. Customers will appreciate Lucki’s hygienic delivery capabilities.</p>
                <a onClick={() => history('/product/lucki')}>Learn More <i class="bi bi-arrow-right"></i></a>
              </div>
            </li>
            <li>
              <div>
                <h2>Mini</h2>
                <span>New Retail Robot</span>
                <p>Mini, is a master of greeting and leading. The powerful voice function and large interactive screen are perfect for a variety of reception needs, and the smaller body is more flexible to cover a greater range of scenarios. Mini provides customers an unparalleled service experience.</p>
                <a onClick={() => history('/product/mini')}>Learn More <i class="bi bi-arrow-right"></i></a>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </motion.div>
  )
}

export default LandingPage;