import React, { useEffect, useState, useRef } from "react";
import PageHeader from "../../../Components/PageHeader/PageHeader";
import "./Contact.css";
import { motion } from "framer-motion";
import emailjs from "@emailjs/browser";
import Reaptcha from "reaptcha";

const Contact = () => {
	const [name, setName] = useState("");
	const [email, setEmail] = useState("");
	const [phone, setPhone] = useState("");
	const [subject, setSubject] = useState("");
	const [message, setMessage] = useState("");

	const [responseMsg, setResponseMsg] = useState("");
	const [captchaReady, setCaptchaReady] = useState(false);
	const form = useRef();
	var captcha = useRef(null);

	useEffect(() => {
		window.scroll(0, 0);
	}, []);

	const submitForm = (e) => {
		e.preventDefault();

		// Reset the response message
		setResponseMsg("");

		// Only send the email when the captcha is ready
		if (!isValidEmail(email)) {
			setResponseMsg("Please enter a valid email");
			setCaptchaReady(false);
			return;
		}

		if (name && email && message && phone && subject) {
			if (captchaReady) {
				// Reset the captcha once the user has submitted the form.
				captcha.reset();

				emailjs.sendForm("service_uc8kf6i", "template_kkr9gff", form.current, "Styk9dy3RUPlI41UP").then(
					(result) => {
						console.log("sendForm: " + result.text);
						setResponseMsg("Your enquiry has been received. Thank you.");
						setCaptchaReady(false);
					},
					(error) => {
						console.log(error.text);
						setResponseMsg("Sorry. Error Occured. Please retry.");
						setCaptchaReady(false);
					}
				);
			} else {
				setResponseMsg("Please click I'm not a robot. ");
				setCaptchaReady(false);
			}
		} else {
			setResponseMsg("Please enter all the fields");
			setCaptchaReady(false);
		}
	};

	// Check for valid email
	const isValidEmail = (email) => {
		const regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
		return regex.test(String(email).toLowerCase()) && email != "";
	};

	// The functions for the recaptcha component
	const onVerify = (recaptchaResponse) => {
		setCaptchaReady(true);
	};

	const onExpire = (recaptchaResponse) => {
		captcha.reset();
		setCaptchaReady(false);
	};

	return (
		<motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
			<PageHeader name="Contact Us" />
			<div className="content-form-container">
				<div className="content-sizing contact-form-wrapper">
					<img src="./Images/pics/contact.jpg" width="400" alt="Contact Us"></img>
					<form ref={form} className="contact-form">
						<div className="form-header-info">
							<span>Let's Talk</span>
							<h2>GET IN TOUCH</h2>
							<p>If you have any question regarding any information. Please feel free to contact us.</p>
						</div>
						<div className="two-col">
							<div className="field-container">
								<input type="text" placeholder="Name" name="name" value={name} onChange={(e) => setName(e.target.value)}></input>
							</div>
							<div className="field-container">
								<input type="text" placeholder="Email" name="email" value={email} onChange={(e) => setEmail(e.target.value)}></input>
							</div>
						</div>
						<div className="two-col">
							<div className="field-container col-2-desgine">
								<input type="text" placeholder="Phone Number" name="phone" value={phone} onChange={(e) => setPhone(e.target.value)}></input>
							</div>
							<div className="field-container col-2-desgine">
								<input placeholder="Subject" name="subject" value={subject} onChange={(e) => setSubject(e.target.value)}></input>
							</div>
						</div>
						<textarea className="textarea-desgine" placeholder="Message" name="message" value={message} onChange={(e) => setMessage(e.target.value)}></textarea>
						<Reaptcha ref={(e) => (captcha = e)} sitekey="6LdJApAhAAAAAIkXHs_4YvvADEd4hPIZLofwYKYt" onVerify={onVerify} onExpire={onExpire} />
						<button onClick={submitForm} className="general-btn general-btn-for-btn">
							Send Inquire
						</button>
						{responseMsg ? <div>{responseMsg}</div> : ""}
					</form>
				</div>
			</div>
			<iframe
				title="Our address"
				width="100%"
				height="600"
				frameborder="0"
				scrolling="no"
				marginheight="0"
				marginwidth="0"
				src="https://maps.google.com/maps?width=100%25&amp;height=600&amp;hl=en&amp;q=%201365%20Pembina%20Hwy%20Winnipeg,%20MB,%20Canada%20R3T%202B7+(Canada%20One%20Robotics)&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
			></iframe>
		</motion.div>
	);
};

export default Contact;
