import { useEffect, useState } from "react";
import {Link} from 'react-router-dom';
import { useNavigate, useParams } from "react-router-dom";
const Settings = (props) => {
  const history = useNavigate();
  const [error, setError] = useState("");
  const [userInput, setUserInput] = useState({
    Date: new Date().toLocaleDateString("en-US"),
    Type:"",
    Title:"",
    Description:""
  });


  const updateUserInput = (e) => {
    setUserInput(prevInput => ({
      ...prevInput, [e.target.name]: e.target.value
    }));
  }

  
  const onSubmit = async (e) => {
    e.preventDefault();
    var item = userInput;
    try{
      let userDetails = await props.add("Pages",item);
      history('/dashboard/robotPage');
    }
    catch(e){
      setError(e.message);
    }
  }
  return (
  <>
        <div className='header-content-right-page'>
      <div className='content-sizing-db wrapper-db-content'>
        <div className='header-and-create-button'>
          <h3>Settings</h3>
          <div class="settings">
          <Link to="/dashboard/robotPage" className="btn-general primary-btn"> Change Password</Link>
          <Link to="/dashboard/robotPage" className="btn-general primary-btn"> Change Page Order</Link>
          </div>
        </div>
      </div>
      
    </div>
  </>
  )
}


export default Settings;