import { useEffect, useState } from "react";
import React from 'react';
import ReactDOM from 'react-dom';
import {Link} from 'react-router-dom';
import { useNavigate, useParams } from "react-router-dom";
const RobotPageEdit = (props) => {
  const history = useNavigate();
  const {id} = useParams();

  const [error, setError] = useState("");
  const [userInput, setUserInput] = useState({
    ...props.pages.find((itm) => itm.id == id).data
  });
  const updateUserInput = (e) => {
    setUserInput(prevInput => ({
      ...prevInput, [e.target.name]: e.target.value
    }));
  }

  
  const onSubmit = async (e) => {
    e.preventDefault();
    var item = userInput;
    item.html = document.getElementById("editArr").innerHTML;
    try{
      let userDetails = await props.edit(item, "Pages",id);
      history('/dashboard/robotPage');
    }
    catch(e){
      setError(e.message);
    }
  }

  return (
  <>
   <div className='header-content-right-page'>
      <div className='content-sizing-db wrapper-db-content'>
        <div className='header-and-create-button'>
          <h3> Page Edit</h3>
          <Link to="/dashboard/robotPage" className="btn-general primary-btn"><i className="bi bi-arrow-left"></i> Back</Link>
        </div>
        <form onSubmit={onSubmit} className="card card-light card-body border-0 shadow-sm p-4 mt-5" id="basic-info">
  <div className="row form-row">
  <h2 className="h4 mb-4">{userInput.Title}</h2>
  
  <button type="submit" className="btn-general primary-btn blue mb-4" > Submit</button>
  </div>
  <div className="row mt-3">


    <div className="col">
      <label className="form-label text-dark" htmlFor="c-name">Title</label><input
        className="form-control form-control-md form-control-dark"
        disabled
        id="title"
        name="Title"
        value={userInput.Title}
        type="text"
        onChange={updateUserInput}
      />
    </div>

    <div className="col">
      <label className="form-label text-dark" htmlFor="c-name">Path</label><input
      disabled
        className="form-control form-control-md form-control-dark"
        id="Path"
        value={userInput.Path}
        name="Path"
        type="text"
        onChange={updateUserInput}
      />
    </div>
    
  </div>
 
</form>
          <section className=" drop card card-light card-body border-0 shadow-sm p-4 mt-5" id="basic-info">
          <label className="form-label text-dark" htmlFor="c-name">Description</label>
              <div className="row">
                
             <div className="col">
              <div className="border-0  shadow-sm width-editor" id="editArr" contenteditable="true" dangerouslySetInnerHTML={{__html: userInput.html}}>
        </div>
            </div>
          </div>
          </section>
      </div>
      
    </div>
  </>
  )
}


export default RobotPageEdit;