import React from 'react'
import "./Loading.css";

const Loading = () => {
  return (
    <div className='loading-container'>
      <div id="loading">
      <i class="bi bi-robot"></i>
      </div>
    </div>
  )
}

export default Loading;