import React from 'react'
import "./PageHeader.css";

const PageHeader = (props) => {
  return (
    <div className='page-header-container'>
      <div className='overlay-dark-filter'>
        <div className='content-sizing page-header-wrapper'>
          <h2>{props.name}</h2>
          <p><span className='primary-color'>Home</span> <i class="bi bi-chevron-right"></i> {props.name}</p>
        </div>
      </div>
    </div>
  )
}

export default PageHeader;
