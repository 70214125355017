import React from 'react'
import { Link } from 'react-router-dom';
import "./Footer.css";
import logo from "../../Util/bw-logo.png";
const Footer = () => {
  return (
    <>
      <footer>
        <div className='content-sizing footer-content-info'>
          <ul>
            <li className='company-info'>
              <img src={logo} width={230}></img>
              <p>Canada One Robotics is a Canadian-owned robotics technology marketing company that serves as the general reseller for Orion Star Robotics in Canada.</p>
              <Link className='general-btn' to="/about-us">Learn More</Link>
            </li>
            <li>
              <div className='footer-box-header'>
                <h2>Our Products</h2>
                <div className='small-line-title'></div>
              </div>
              <ul>
                <li><Link to="/product/lucki">Lucki</Link></li>
                <li><Link to="/product/mini">Mini</Link></li>
              </ul>
            </li>
            <li>
              <div className='footer-box-header'>
                <h2>Quick Links</h2>
                <div className='small-line-title'></div>
              </div>
              <ul>
                <li><Link to="/">Home</Link></li>
                <li><Link to="/about-us">About Us</Link></li>
                <li><Link to="/contact-us">Contact</Link></li>
              </ul>
            </li>
            <li className='company-info'>
              <div className='footer-box-header '>
                <h2>Contact</h2>
                <div className='small-line-title'></div>
              </div>
              <ul className='contact-info-footer'>
                <li><span>Address: </span>&nbsp;1365B Pembina Hwy Winnipeg, MB, Canada R3T 2B7</li>
                <li><span>E-mail: </span><Link to="/contact-us">Contact Us</Link></li>
                <li><span>Phone:</span> +1 (204)-957-8888</li>
              </ul>
            </li>
          </ul>
        </div>
      </footer>
      <div className='footer-copyright-holder'>
        <div className='content-sizing footer-copyright-content'>
          <p>Copyright &copy; 2022 Canada One Robotics. All Rights Reserved.</p>
          <ul>
            <li><Link to="/"><i class="bi bi-twitter"></i></Link></li>
            <li><Link to="/"><i class="bi bi-linkedin"></i></Link></li>
            <li><Link to="/"><i class="bi bi-facebook"></i></Link></li>
          </ul>
        </div>
      </div>
    </>
  )
}

export default Footer;